/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actionTypes from './Promotion-ActionTypes';

export const updateFieldPromotionForm = (data) => ({
    type: actionTypes.UPDATE_FIELD_PROMOTION_FORM,
    data,
});

export const updatePromoAndMemberId = (promotionCode, membershipNumber) => ({
    type: actionTypes.UPDATE_PROMO_AND_MEMBERID,
    data: {
        promotionCode,
        membershipNumber,
    },
});

export const clearAllFieldPromotionForm = () => ({
    type: actionTypes.CLEAR_ALL_FIELD_PROMOTION_FORM,
});

export const setBannerCodesAttempted = (bannerCodesAttempted) => ({
    type: actionTypes.SET_BANNER_CODES_ATTEMPTS,
    data: {
        bannerCodesAttempted,
    },
});

export const applyPromotionIsLoading = (bool) => ({
    type: actionTypes.SET_PROMOTION_LOAD,
    payload: bool,
});

export const redirectToPromoAffiliate = (affiliatePartner) => ({
    type: actionTypes.PROMOTION_REDIRECT_TO_AFFILIATE,
    payload: affiliatePartner,
});
